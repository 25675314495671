html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  line-height: 1;
  box-sizing: inherit;
}

*:focus {
  border: 0;
  outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  margin: 0;
  padding: 0;
  display: block;
}

audio,
canvas,
progress,
video {
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: bottom;
}

header {
  padding-top: 30px;
  height: 116px;
}

button {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  height: 40px;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  color: #fff;
  background-color: #201238;
  border: 0;
  text-decoration: none;
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  white-space: pre-wrap;
  transition: background .3s ease;
}

button + button {
  margin-top: 12px;
}

button:hover,
button:focus {
  background: #4d667f;
}

.buttons {
  margin: auto auto 28px;
  width: 100%;
  max-width: 300px;
}

h1 {
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
}

h2 {
  margin-top: 60px;
  margin-bottom: 48px;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
}

h3 {
  margin-right: 40px;
  margin-left: 40px;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}

header + .subheader h2 {
  margin-top: 0px;
}

.xo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 114px;
  width: 100%;
  font-family: 'Baskerville', serif;
  font-size: 48px;
  line-height: 55px;
  list-style: none;
}

.xo li {
  flex: 0px 0px auto;
  margin: 0 40px;
  padding: 5px 10px;
  border-bottom: 5px solid #fff;
  border-color: #fff;
  transition: border-color .3s ease;
  cursor: pointer;
}

.xo li:hover,
.xo li:focus {
  border-color: #5CB85C;
}

.xo.selected li {
   border-color: transparent;
}

.subheader {
  height: 48px;
}