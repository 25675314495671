.App {
  background: linear-gradient(180deg, #627A96 0%, #83B5C5 19.47%, #8DC7D4 39.07%, #93D2DC 82.24%, #95D6DF 100%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  height: 530px;
  width: 100%;
  max-width: 320px;
}
